import React, { useEffect, useRef } from 'react'

export default function Redirect() {
  const initialized = useRef(false)
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      const redirect = localStorage.getItem('redirect')

      if (redirect) {
        localStorage.removeItem('redirect')
        window.location.href = redirect
      } else {
        window.location.href = '/home'
      }
    }
  }, [])
  return (<div />)
}
