import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AuthGuard from '../auth/AuthGuard'
import Redirect from '../auth/Redirect'
import LandingLogic from '../auth/Login/LandingLogic'
import Logout from '../auth/Logout/Logout'
import Login from '../auth/Login/Login'
import { MyAccount } from '../../pages/MyAccount'
import { EditAccount } from '../EditAccount'
import { ResetPassword } from '../../pages/ResetPassword'
import { Dashboard } from '../../pages/Dashboard'
import { ErrorPage } from '../error/ErrorPage'
import { Health } from '../../pages/Health'
import { Support } from '../../pages/Support'
import { Security } from '../../pages/Security'
import styles from './XIAMRouters.module.scss'

export default function XIAMRouters() {
  const AuthenticatedDashboard = AuthGuard(Dashboard)
  const AuthenticatedMyAccount = AuthGuard(MyAccount)
  const AuthenticatedEditAccount = AuthGuard(EditAccount)
  const AuthenticatedResetPassword = AuthGuard(ResetPassword)
  const AuthenticatedSupport = AuthGuard(Support)
  const AuthenticatedSecurity = AuthGuard(Security)

  return (
    <div className={styles['page-container']}>
      <Routes>
        <Route exact path="/" element={<LandingLogic />} />
        <Route exact path="/home" element={<AuthenticatedDashboard />} />
        <Route exact path="/account" element={<AuthenticatedMyAccount />} />
        <Route exact path="/edit-account" element={<AuthenticatedEditAccount />} />
        <Route exact path="/reset-password" element={<AuthenticatedResetPassword />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/redirect" element={<Redirect />} />
        <Route exact path="/error" element={<ErrorPage />} />
        <Route exact path="/health" element={<Health />} />
        <Route exact path="/support" element={<AuthenticatedSupport />} />
        <Route exact path="/security" element={<AuthenticatedSecurity />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  )
}
