import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gatewayRequestAxios } from '../../api/interceptor'
import { GATEWAY_API_USER_URL } from '../../util/config'

export const initialUserObj = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  secondEmail: '',
  preferredLanguage: '',
  login: '',
  mobilePhone: '',
  status: '',
  created: '',
  activated: '',
  statusChanged: '',
  lastLogin: '',
  lastUpdated: '',
  passwordChanged: '',
  profession: '',
  city: '',
  state: '',
  zip: '',
  addressLine: ''
}

const initialState = {
  userObj: {
    ...initialUserObj
  },
  status: 'idle',
  call: 'idle',
  error: null
}

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const response = await gatewayRequestAxios.get(GATEWAY_API_USER_URL)
  if (response.status === 200) {
    localStorage.setItem('loggedIn', 'true')
  }
  return response.data
})

export const updateUser = createAsyncThunk('user/updateUser', async (updatedFields, { getState }) => {
  const state = getState()
  const currentUser = state.user.userObj
  const updatedUserObj = { ...currentUser, ...updatedFields }

  const response = await gatewayRequestAxios.put(GATEWAY_API_USER_URL, updatedUserObj)
  return response.data
})

export const fetchUserLanguage = createAsyncThunk(
  'user/fetchUserLanguage', async () => {
    const response = await gatewayRequestAxios.get(`${GATEWAY_API_USER_URL}/language`)
    return response.data
  })

export const updateUserLanguage = createAsyncThunk(
  'user/updateUserLanguage',
  (preferredLanguage) => gatewayRequestAxios.put(`${GATEWAY_API_USER_URL}/language`, preferredLanguage)
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading'
        state.userObj = { ...initialUserObj }
        state.error = undefined
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.error = undefined
        state.userObj = action.payload

        // If a value for branding is returned, write to local storage for future logic
        if (action.payload.branding) {
          localStorage.setItem('branding', action.payload.branding)
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed'
        state.userObj = {}
        state.error = action.error.message
      })
      .addCase(updateUser.pending, (state) => {
        state.status = 'loading'
        state.error = undefined
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.userObj = { ...state.userObj, ...action.payload }
        state.error = undefined
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(updateUserLanguage.pending, (state) => {
        state.status = 'loading'
        state.error = undefined
      })
      .addCase(updateUserLanguage.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.userObj.preferredLanguage = action.payload.preferredLanguage
        state.error = undefined
      })
      .addCase(updateUserLanguage.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchUserLanguage.pending, (state) => {
        state.status = 'loading'
        state.error = undefined
      })
      .addCase(fetchUserLanguage.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.userObj.preferredLanguage = action.payload.preferredLanguage
        state.error = undefined
      })
      .addCase(fetchUserLanguage.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export default userSlice.reducer

// selectors
export const selectUserObject = (state) => state.user.userObj
export const selectUserCallStatus = (state) => state.user.status
export const selectUserCallError = (state) => state.user.error
