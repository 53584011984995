import { API_AUTH_COOKIE_NAME, GATEWAY_API_LOGIN } from './config'

export const isAuthenticated = () => {
  const cookies = `; ${document.cookie}`.split('; ')
  const filtered = cookies.filter((str) => str.indexOf(`${API_AUTH_COOKIE_NAME}=`) === 0)

  return filtered.length > 0
}

export const handleUnauthenticated = () => {
  if (sessionStorage.getItem('wasLoggedIn')) {
    sessionStorage.setItem('logoutWarning', 'true')
  } else {
    sessionStorage.removeItem('logoutWarning')
  }

  localStorage.setItem('redirect', window.location.pathname)
  const branding = localStorage.getItem('branding')

  if (branding) {
    window.location = `${GATEWAY_API_LOGIN}?branding=${branding}`
  } else {
    window.location = GATEWAY_API_LOGIN
  }
}
