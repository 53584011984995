import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from './SurveyFreeFormQuestion.module.scss'

function SurveyFreeFormQuestion({
  promptText, onTextChange, textValue, customClass
}) {
  const { t } = useTranslation()

  const handleTextChange = (e) => {
    onTextChange(e.target.value)
  }

  return (
    <div className={`${styles.surveyFreeFormQuestion} ${customClass || ''}`}>
      <p className={styles.promptText}>{promptText}</p>
      <textarea
        className={styles.textBox}
        value={textValue}
        onChange={handleTextChange}
        placeholder={t('feedback-placeholder-text')}
        aria-label="Survey Freeform Textbox"
      />
    </div>
  )
}

SurveyFreeFormQuestion.propTypes = {
  promptText: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  textValue: PropTypes.string.isRequired,
  customClass: PropTypes.string
}

SurveyFreeFormQuestion.defaultProps = {
  customClass: ''
}

export default SurveyFreeFormQuestion
