import React from 'react'
import {
  Modal, Box, IconButton, Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styles from './FeedbackModal.module.scss'
import { Button } from '../section-components/Button'
import DynamicSurvey from '../section-components/DynamicSurvey'
import {
  submitSurveyResponse, setSurveyResponses, resetSurveyResponses, setIsSurveySubmitted
} from '../../pages/Survey/surveySlice'
import { selectUserObject } from '../user/userSlice'
import { selectSurveyTemplates } from '../../pages/Survey/surveyTemplateSlice'
import isMobileView from '../../util/MobileView'

const transformSurveyTemplate = (templateData) => {
  if (!templateData) return null

  return {
    id: templateData.template_id,
    schema: templateData.template_tag,
    questions: templateData.questions,
  }
}

function FeedbackModal({
  open, onClose, onMute, onSurveySubmit, onRemindLater, templateTag, buttonColors
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userObjData = useSelector(selectUserObject)
  const surveyResponses = useSelector((state) => state.survey.surveyResponses)
  const isSurveySubmitted = useSelector((state) => state.survey.isSurveySubmitted)
  const surveyTemplate = useSelector(selectSurveyTemplates)

  const isMobile = isMobileView()

  const surveySchema = surveyTemplate && surveyTemplate[templateTag]
    ? transformSurveyTemplate(surveyTemplate[templateTag])
    : null

  function transformResponseObjToArray(responseObj) {
    return Object.keys(responseObj).map((questionId) => ({
      id: questionId,
      response: responseObj[questionId],
    }))
  }

  const handleSurveyChange = (newResponses) => {
    dispatch(setSurveyResponses(newResponses))
  }

  const handleSubmit = () => {
    const formattedSurveyResponse = transformResponseObjToArray(surveyResponses)
    dispatch(submitSurveyResponse({ id: surveySchema.id, responses: formattedSurveyResponse }))
      .unwrap()
      .then(() => {
        dispatch(setIsSurveySubmitted(true))
        dispatch(resetSurveyResponses())
        onSurveySubmit()
      })
  }

  const handleClose = () => {
    onClose()
    dispatch(setIsSurveySubmitted(false))
    dispatch(resetSurveyResponses())
  }

  const handleRemindLater = () => {
    onRemindLater()
    onClose()
  }

  if (isSurveySubmitted) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box className={`${styles.modalContainer} ${isMobile ? styles.mobileModal : ''}`}>
          <div className={`${styles.closeButtonRight} ${isMobile ? styles.closeButtonMobile : ''}`}>
            <Button
              text={t('feedback-modal-close-button')}
              buttonStyle="cta-red-oval"
              onClick={handleClose}
            />
          </div>
          <div className={styles.closeButton}>
            <IconButton onClick={handleClose} aria-label="close">
              <div className={styles.closeIcon}>
                <CloseIcon />
              </div>
            </IconButton>
          </div>
          <div className={styles.thankYouText}>
            <h2>{t('feedback-modal-thankyou-header')}</h2>
            <p className={styles.thankYouMessage}>{t('feedback-modal-thankyou-message')}</p>
          </div>
        </Box>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={`${styles.modalContainer} ${isMobile ? styles.mobileModal : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className={styles.closeButton}>
          <IconButton onClick={handleClose} aria-label="close">
            <div className={styles.closeIcon}>
              <CloseIcon />
            </div>
          </IconButton>
        </div>
        <div className={styles.title}>
          {t('feedback-modal-title')}
        </div>
        <div className={styles.prompt}>
          {t('feedback-modal-prompt')}
        </div>
        <div className={styles.surveyContainer}>
          {surveySchema && (
            <DynamicSurvey
              surveySchema={surveySchema}
              userObjData={userObjData}
              buttonColors={buttonColors}
              onChange={handleSurveyChange}
            />
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Typography
            onClick={onMute}
            className={styles.muteText}
            sx={{
              fontSize: '12px',
              fontFamily: 'JohnsonText',
            }}
          >
            {t('feedback-dont-ask-week')}
          </Typography>

          <div className={`${styles.buttonContainer}
          ${isMobile ? styles.buttonContainerMobile : styles.buttonContainerDesktop}`}
          >
            <Button
              text={t('feedback-submit')}
              buttonStyle={isMobile ? 'cta-red-line-oval-medium' : 'cta-red-oval'}
              onClick={handleSubmit}
              disabled={Object.keys(surveyResponses).length === 0}
            />
            <Button
              text={t('feedback-remind-later')}
              buttonStyle={isMobile ? 'cta-grey-oval-modal' : 'cta-grey-oval-large'}
              onClick={handleRemindLater}
            />
          </div>
        </div>
      </Box>
    </Modal>
  )
}

FeedbackModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMute: PropTypes.func.isRequired,
  onSurveySubmit: PropTypes.func.isRequired,
  onRemindLater: PropTypes.func.isRequired,
  templateTag: PropTypes.func.isRequired,
  surveySchema: PropTypes.shape({
    id: PropTypes.string.isRequired,
    schema: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.objectOf(PropTypes.string).isRequired,
      answers: PropTypes.arrayOf(PropTypes.shape({
        'en-US': PropTypes.string.isRequired,
        'es-ES': PropTypes.string.isRequired,
        'ja-JP': PropTypes.string.isRequired,
        'ko-KR': PropTypes.string.isRequired,
        'nl-NL': PropTypes.string.isRequired,
        'de-DE': PropTypes.string.isRequired,
        'pt-PT': PropTypes.string.isRequired,
        'fr-FR': PropTypes.string.isRequired,
      })),
      required: PropTypes.bool,
    })).isRequired,
  }).isRequired,
  buttonColors: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default FeedbackModal
