import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from './Button'

export default function InteractiveSnackbar(props) {
  return (
    <Snackbar
      TransitionComponent={props.transitionType}
      anchorOrigin={{ vertical: props.verticalPosition, horizontal: props.horizontalPosition }}
      open={props.isOpen}
      message={props.alertMessage}
      action={(
        <>
          <Button text={props.buttonMessage} onClick={props.onClick} buttonStyle="cta-grey-oval-snackbar" />
          <CloseIcon onClick={props.onClose} style={{ marginLeft: '20px', fontSize: '1.2rem', color: '#FFF' }} />
        </>
      )}
      ContentProps={{
        style: {
          backgroundColor: '#564C47',
          color: '#FFFFFF',
          fontSize: '16px'
        }
      }}
    />
  )
}

InteractiveSnackbar.propTypes = {
  alertMessage: PropTypes.string.isRequired,
  buttonMessage: PropTypes.string,
  isOpen: PropTypes.bool,
  verticalPosition: PropTypes.oneOf(['top', 'bottom']),
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  transitionType: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

InteractiveSnackbar.defaultProps = {
  buttonMessage: 'Action',
  isOpen: true,
  verticalPosition: 'top',
  horizontalPosition: 'center',
}
