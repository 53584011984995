import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gatewayRequestAxios } from '../../api/interceptor'
import { GATEWAY_API_SURVEY, SURVEY_TEMPLATE } from '../../util/config'

const initialState = {
  surveyObj: {},
  status: 'idle',
  call: 'idle',
  error: null,
  showFeedbackModal: false,
  isSubmitted: false,
  surveyResponses: {},
  isSurveySubmitted: false,
}

export const submitSurveyResponse = createAsyncThunk(
  `surveys/${SURVEY_TEMPLATE.id}/response`,
  async (payload) => {
    const response = await gatewayRequestAxios.post(`${GATEWAY_API_SURVEY}/${payload.id}/response`, payload.responses)

    return response.data
  })

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setShowFeedbackModal: (state, action) => {
      state.showFeedbackModal = action.payload
    },
    setIsSubmitted: (state, action) => {
      state.isSubmitted = action.payload
    },
    setSurveyResponses: (state, action) => {
      state.surveyResponses = action.payload
    },
    resetSurveyResponses: (state) => {
      state.surveyResponses = {}
    },
    setIsSurveySubmitted: (state, action) => {
      state.isSurveySubmitted = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(submitSurveyResponse.pending, (state) => {
        state.status = 'loading'
        state.surveyObj = {}
        state.error = undefined
      })
      .addCase(submitSurveyResponse.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.error = undefined
        state.surveyObj = action.payload
        state.isSurveySubmitted = true
      })
      .addCase(submitSurveyResponse.rejected, (state, action) => {
        state.status = 'failed'
        state.surveyObj = {}
        state.error = action.error.message
      })
  }
})

export const {
  setShowFeedbackModal, setIsSubmitted, setSurveyResponses, resetSurveyResponses,
  setIsSurveySubmitted
} = surveySlice.actions

export const selectSurveyObject = (state) => state.survey.surveyObj
export default surveySlice.reducer
