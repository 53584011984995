import axios from 'axios'
import { setOverlayError } from '../features/error/errorSlice'
import { EXCEPTION_ROUTES } from '../util/config'
import { handleUnauthenticated } from '../util/auth'

const gatewayRequestAxios = axios.create({
  timeout: 10000,
})

const setupInterceptors = (store) => {
  gatewayRequestAxios.interceptors.request.use((config) => {
    config.withCredentials = true
    return config
  }, (error) => Promise.reject(error))

  gatewayRequestAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && [401, 403].includes(error.response.status)) {
        if (!EXCEPTION_ROUTES.includes(window.location.pathname)) {
          handleUnauthenticated()
        }
      } else if (error.response && error.response.status >= 500) {
        store.dispatch(setOverlayError({ message: 'An unexpected server error occurred', code: error.response.status }))
      } else if (error.request && !error.response) {
        store.dispatch(setOverlayError({ message: 'Network error', code: 'Network' }))
      }
      return Promise.reject(error)
    }
  )
}

export { gatewayRequestAxios, setupInterceptors }
