/* eslint-disable consistent-return */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Slide from '@mui/material/Slide'
import InteractiveSnackbar from './InteractiveSnackbar'
import FeedbackModal from '../feedback-modal/FeedbackModal'
import { SURVEY_TEMPLATE_TAG_HOME } from '../../util/config'

function SnackbarFeedbackOpp({
  isSnackbarOpen, setSnackbarOpen, modalOpen, setModalOpen,
  buttonColors, isDelayed, surveyResponses, setSurveyResponses
}) {
  const TEN_HOURS = 10 * 60 * 60 * 1000
  const FIFTEEN_MINUTES = 15 * 60 * 1000
  const ONE_WEEK = 7 * 24 * 60 * 60 * 1000

  useEffect(() => {
    if (modalOpen) {
      return
    }

    const now = Date.now()
    const lastSubmitted = localStorage.getItem('lastSurveySubmitted')
    const lastRemindLater = localStorage.getItem('lastRemindLater')
    const lastMuted = localStorage.getItem('lastMuted')

    if (lastMuted && now - lastMuted < ONE_WEEK) {
      return
    }

    if (lastSubmitted && now - lastSubmitted < TEN_HOURS) {
      return
    }

    if (lastRemindLater && now - lastRemindLater < FIFTEEN_MINUTES) {
      return
    }

    if (isDelayed) {
      const timer = setTimeout(() => {
        setSnackbarOpen(true)
      }, 4000)

      return () => clearTimeout(timer)
    }
    setSnackbarOpen(true)

    return undefined
  }, [isDelayed, modalOpen, setSnackbarOpen])

  const handleSnackbarClick = () => {
    setSnackbarOpen(false)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleMuteForWeek = () => {
    localStorage.setItem('lastMuted', Date.now().toString())
    setSnackbarOpen(false)
    setModalOpen(false)
  }

  const handleRemindLater = () => {
    localStorage.setItem('lastRemindLater', Date.now().toString())
    setSnackbarOpen(false)
  }

  const handleSurveySubmitted = () => {
    localStorage.setItem('lastSurveySubmitted', Date.now().toString())
    setSnackbarOpen(false)
  }

  return (
    <div>
      <InteractiveSnackbar
        alertMessage="Help us improve"
        buttonMessage="Leave Feedback"
        isOpen={isSnackbarOpen}
        verticalPosition="bottom"
        horizontalPosition="center"
        transitionType={Slide}
        onClick={handleSnackbarClick}
        onClose={handleSnackbarClose}
      />

      <FeedbackModal
        open={modalOpen}
        onClose={handleModalClose}
        onMute={handleMuteForWeek}
        buttonColors={buttonColors}
        templateTag={SURVEY_TEMPLATE_TAG_HOME}
        onSurveySubmit={handleSurveySubmitted}
        onRemindLater={handleRemindLater}
        surveyResponses={surveyResponses}
        setSurveyResponses={setSurveyResponses}
      />
    </div>
  )
}

SnackbarFeedbackOpp.propTypes = {
  isSnackbarOpen: PropTypes.bool.isRequired,
  setSnackbarOpen: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  buttonColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDelayed: PropTypes.bool,
  surveyResponses: PropTypes.shape({
    responseId: PropTypes.string,
    feedback: PropTypes.string,
  }).isRequired,
  setSurveyResponses: PropTypes.func.isRequired,
}

SnackbarFeedbackOpp.defaultProps = {
  isDelayed: false,
}

export default React.memo(SnackbarFeedbackOpp)
