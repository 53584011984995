/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectUserObject,
  selectUserCallStatus,
  fetchUser,
} from '../../features/user/userSlice'
import {
  selectUserAppsObject,
  getUserAppsCallStatus,
  fetchUserApps,
} from '../../features/user-apps/UserAppsSlice'
import {
  selectProfilePictureImage,
  selectProfilePictureStatus,
  fetchProfilePicture
} from '../../features/user/profilePictureSlice'
import styles from './Dashboard.module.scss'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import { changePage, selectPage } from '../../features/side-menu/SideMenuSlice'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import isMobileView from '../../util/MobileView'
import '../../util/i18n'
import DashboardDesktop from './DashboardDesktop'
import DashboardMobile from './DashboardMobile'
import SnackbarFeedbackOpp from '../../features/section-components/snackbarFeedbackOpp'
import { SURVEY_TEMPLATE_TAG_HOME } from '../../util/config'

export function Dashboard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userObjData = useSelector(selectUserObject)
  const userObjStatus = useSelector(selectUserCallStatus)
  const userAppsData = useSelector(selectUserAppsObject)
  const userAppsStatus = useSelector(getUserAppsCallStatus)
  const userProfilePictureData = useSelector(selectProfilePictureImage)
  const userProfilePictureStatus = useSelector(selectProfilePictureStatus)
  const currPage = useSelector(selectPage)
  const isMobile = isMobileView()
  const mobileIdentifier = `mobile-${isMobile}`

  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  const [surveyResponses, setSurveyResponses] = useState({})

  const DashboardView = isMobile ? DashboardMobile : DashboardDesktop
  const [isModalOpen, setModalOpen] = useState(() => localStorage.getItem('isModalOpen') === 'true')

  useEffect(() => {
    localStorage.setItem('isModalOpen', isModalOpen.toString())
  }, [isModalOpen])

  const handleModalClose = () => {
    setModalOpen(false)
    localStorage.setItem('isModalOpen', 'false')
  }

  useEffect(() => {
    if (currPage !== '/') {
      dispatch(changePage('/'))
    }
  }, [currPage, dispatch])

  useEffect(() => {
    if (userObjStatus === 'idle') {
      dispatch(fetchUser())
    }
  }, [userObjStatus, dispatch])

  useEffect(() => {
    if (userAppsStatus === 'idle') {
      dispatch(fetchUserApps())
    }
  }, [userAppsStatus, dispatch])

  useEffect(() => {
    if (userProfilePictureStatus === 'idle') {
      dispatch(fetchProfilePicture())
    }
  }, [userProfilePictureStatus, dispatch])

  const appGridIsLoading = userAppsStatus === 'loading' || userAppsStatus === 'idle'
  const isPageLoading = !(
    (userObjStatus === 'succeeded' || userObjStatus === 'failed')
      && (userProfilePictureStatus === 'succeeded'
        || userProfilePictureStatus === 'failed')
  )

  const handleSurveyChange = (responses) => {
    setSurveyResponses(responses)
  }

  return (
    <MarginWrapper>
      <div className={styles['dashboard-page']}>
        <div id={mobileIdentifier} />
        <LoadingIcon isLoading={isPageLoading}>
          <DashboardView
            t={t}
            userObjData={userObjData}
            userAppsData={userAppsData}
            appGridIsLoading={appGridIsLoading}
            isSnackbarOpen={isSnackbarOpen}
            setSnackbarOpen={setSnackbarOpen}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            surveyResponses={surveyResponses}
            setSurveyResponses={handleSurveyChange}
          />
        </LoadingIcon>
        <SnackbarFeedbackOpp
          isSnackbarOpen={isSnackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          modalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          buttonColors={['green', '#D7AD01', 'red']}
          templateTag={SURVEY_TEMPLATE_TAG_HOME}
          isDelayed
          surveyResponses={surveyResponses}
          setSurveyResponses={setSurveyResponses}
        />
      </div>
    </MarginWrapper>
  )
}

export default Dashboard
