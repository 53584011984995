import React, { useEffect, useRef } from 'react'
import { isAuthenticated } from '../../../util/auth'

export default function LandingLogic() {
  const initialized = useRef(false)
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      if (isAuthenticated()) {
        window.location.href = '/home'
      } else {
        window.location.href = '/login'
      }
    }
  }, [])
  return (<div />)
}
