import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl, InputLabel, OutlinedInput, FormHelperText,
  CssBaseline
} from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import styles from './Textbox.module.scss'

const theme = createTheme({
  typography: {
    fontFamily: '"JohnsonText", Arial, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightBold: 700
  }
})

export function TextboxMobile(props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormControl error={props.error} className={styles.mobile}>
        <InputLabel
          shrink
          htmlFor={`${props.label}-textbox`}
          className={styles['textbox-label']}
          sx={{
            '&.Mui-focused': {
              color: '#4545E0'
            }
          }}
        >
          {props.label}
        </InputLabel>
        <OutlinedInput
          id={`${props.label}-textbox`}
          type={props.type}
          placeholder={props.placeholderText}
          value={props.value}
          onChange={props.onChange}
          className={styles[`${props.type}-textbox`]}
          notched={false}
          label={props.label}
          inputComponent={props.inputComponent}
          aria-describedby={`${props.label}-textbox-helper`}
          sx={{
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4545E0',
            },
            '&.Mui-disabled': {
              background: '#F2E7E6'
            },
            borderRadius: '10px',
          }}
          onBlur={props.onBlur}
          required={props.required}
          disabled={props.disabled}
        />
        <FormHelperText
          className={styles['textbox-support-text']}
          id={`${props.label}-textbox-helper`}
        >
          {props.error ? props.supportText : ''}
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  )
}

TextboxMobile.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
  value: PropTypes.string,
  placeholderText: PropTypes.string,
  supportText: PropTypes.string,
  inputComponent: PropTypes.elementType,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}

TextboxMobile.defaultProps = {
  label: '',
  type: 'text',
  value: '',
  placeholderText: '',
  supportText: '',
  inputComponent: null,
  onChange: () => {},
  onBlur: () => {},
  error: false,
  required: false,
  disabled: false,
}
