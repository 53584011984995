import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SURVEY_TEMPLATES } from '../../util/config'

const initialState = {
  status: 'idle',
  call: 'idle',
  error: null,
  templates: {},
  isSnackbarOpen: false,
  isFeedbackModalOpen: false,
}

export const fetchSurveyTemplates = createAsyncThunk(
  'surveys/surveyTemplates',
  async () => {
    const response = await axios.get(`${SURVEY_TEMPLATES}`)

    return response.data
  })

export const selectSurveyTemplates = (state) => state.surveyTemplates.templates
export const selectSurveyStatus = (state) => state.surveyTemplates.status
export const selectIsSnackbarOpen = (state) => state.surveyTemplates.isSnackbarOpen
export const selectIsFeedbackModalOpen = (state) => state.surveyTemplates.isFeedbackModalOpen

const surveyTemplateSlice = createSlice({
  name: 'surveyTemplates',
  initialState,
  reducers: {
    setSurveyTemplates: (state, action) => {
      state.templates = action.payload
    },
    openSnackbar: (state) => {
      state.isSnackbarOpen = true
    },
    closeSnackbar: (state) => {
      state.isSnackbarOpen = false
    },
    openFeedbackModal: (state) => {
      state.isFeedbackModalOpen = true
    },
    closeFeedbackModal: (state) => {
      state.isFeedbackModalOpen = false
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSurveyTemplates.pending, (state) => {
        state.status = 'loading'
        state.templates = {}
        state.error = undefined
      })
      .addCase(fetchSurveyTemplates.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.templates = action.payload
        state.error = undefined
      })
      .addCase(fetchSurveyTemplates.rejected, (state, action) => {
        state.status = 'failed'
        state.templates = {}
        state.error = action.error.message
      })
  }
})

export const selectSurveyTemplateByTag = (tag) =>
  (state) => state.surveyTemplates.templates[tag] || null

export const {
  openSnackbar,
  closeSnackbar,
  openFeedbackModal,
  closeFeedbackModal,
  setSurveyTemplates
} = surveyTemplateSlice.actions

export default surveyTemplateSlice.reducer
