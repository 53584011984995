/* eslint-disable no-undef */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Paper } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import { useLocation } from 'react-router-dom'
import styles from './Login.module.scss'
import isMobileView from '../../../util/MobileView'
import { Button } from '../../section-components/Button'
import { GATEWAY_API_LOGIN, SURVEY_TEMPLATE_TAG_LOGOUT } from '../../../util/config'
import Alert from '../../error/Alert'
import FeedbackModal from '../../feedback-modal/FeedbackModal'
import { useLoginLogic } from './useLoginLogic'
import { selectSurveyTemplates } from '../../../pages/Survey/surveyTemplateSlice'

export default function Login({ userObjData }) {
  const { t } = useTranslation()
  const surveyTemplates = useSelector(selectSurveyTemplates)
  const [isModalOpen, setIsModalOpen] = useState(
    localStorage.getItem('isModalOpen') === 'true'
  )
  const [surveyResponses, setSurveyResponses] = useState(
    JSON.parse(localStorage.getItem('surveyResponses') || '{}')
  )

  const [isMobile, setIsMobile] = useState(isMobileView(834))

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView(834))
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const loggedOut = queryParams.get('logout')
  const {
    logoutWarning
  } = useLoginLogic(loggedOut)
  const buttonColors = ['#22BB02', '#F98E2B', '#BB0014']

  const handleMuteForWeek = () => {
    localStorage.setItem('lastMuted', Date.now().toString())
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (loggedOut === 'true' && !localStorage.getItem('isModalOpen')) {
      const timer = setTimeout(() => {
        setIsModalOpen(true)
        localStorage.setItem('isModalOpen', 'true')
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [loggedOut])

  const handleModalClose = () => {
    setIsModalOpen(false)
    localStorage.setItem('isModalOpen', 'false')
  }

  const handleSurveyChange = (newResponses) => {
    setSurveyResponses(newResponses)
    localStorage.setItem('surveyResponses', JSON.stringify(newResponses))
  }

  const renderLoginContent = () => (
    <>
      {logoutWarning && (
      <div style={{ paddingBottom: '24px' }}>
        <Alert
          headerText={t('unauthorized-access-header')}
          message={t('unauthorized-access-message')}
          alertStyle="warning-alert"
          showClose={false}
        />
      </div>
      )}
      {loggedOut === 'true' && (
        <div style={{ paddingBottom: '24px' }}>
          <Alert
            headerText=""
            message={t('signed-out-message')}
            alertStyle="success-one-line"
            showClose={false}
          />
        </div>
      )}
      <div className={isMobile ? styles['mobile-login-title'] : styles['login-title']}>
        {loggedOut === 'true' ? t('welcome-signed-out') : t('welcome-message')}
      </div>
      <div className={isMobile ? styles['mobile-login-sub-title'] : styles['login-sub-title']}>
        {t('sign-in-blurb')}
      </div>
      <div className={isMobile ? styles['login-button-mobile'] : styles['login-button']}>
        <Button
          id="login_button_href"
          text={t('LogIn')}
          buttonStyle="cta-red-oval"
          onClick={() => {
            window.location.href = GATEWAY_API_LOGIN
            sessionStorage.setItem('wasLoggedIn', 'true')
            return false
          }}
        />
      </div>
      <div className={isMobile ? styles['mobile-login-footer-blurb'] : styles['login-footer-blurb']}>
        {t('sign-in-footer-blurb')}
      </div>
    </>
  )

  const surveyTemplate = surveyTemplates[SURVEY_TEMPLATE_TAG_LOGOUT]
  return (
    <div>
      <AppBar className={isMobile ? 'mobile-login-app-bar' : styles['login-app-bar']} sx={{ backgroundColor: '#EB1700', height: isMobile ? '100px' : '140px' }}>
        <Typography className={isMobile ? styles['mobile-login-app-bar-header'] : styles['login-app-bar-header']} sx={{ fontFamily: "'JohnsonDisplay', Arial", fontSize: '30px', fontWeight: 'bolder' }}>
          {t('sign-in-header')}
        </Typography>
      </AppBar>
      <div className={isMobile ? styles['full-viewport-height'] : undefined}>
        <Paper elevation={0} className={isMobile ? styles['mobile-login-content'] : styles['login-content']}>
          {renderLoginContent()}
        </Paper>
      </div>
      {surveyTemplate && (
        <FeedbackModal
          open={isModalOpen}
          onClose={handleModalClose}
          onMute={handleMuteForWeek}
          onSurveySubmit={handleModalClose}
          onRemindLater={handleModalClose}
          surveyResponses={surveyResponses}
          setSurveyResponses={handleSurveyChange}
          templateTag={SURVEY_TEMPLATE_TAG_LOGOUT}
          buttonColors={buttonColors}
          isMobile={isMobile}
          userObjData={userObjData}
        />
      )}
    </div>
  )
}

Login.propTypes = {
  userObjData: PropTypes.shape({
    preferredLanguage: PropTypes.string,
    firstName: PropTypes.string,
  }),
}

Login.defaultProps = {
  userObjData: {
    preferredLanguage: 'en-US',
    firstName: '',
  },
}
