import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
  id: '',
  message: '',
  code: '',
  created: '',
  updated: '',
  overlayMessage: null,
  oauthError: null
}

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.message = action.payload.message
      state.id = action.payload.id
      state.code = action.payload.code
      state.created = action.payload.created
      state.updated = action.payload.updated
    },
    setOverlayError: (state, action) => {
      state.overlayMessage = action.payload.message
      state.overlayCode = action.payload.code
    },
    setOAuthError: (state, action) => {
      state.oauthError = action.payload
    },
  },
})

export default errorSlice.reducer
// selectors
export const selectErrorObject = (state) => state.error
// eslint-disable-next-line max-len
export const selectOverlayError = createSelector(
  (state) => state.error.overlayMessage,
  (state) => state.error.overlayCode,
  (overlayMessage, overlayCode) => ({ message: overlayMessage, code: overlayCode })
)
export const selectOAuthError = (state) => state.error.oauthError
export const { setError, setOverlayError, setOAuthError } = errorSlice.actions
