import React from 'react'
import PropTypes from 'prop-types'
import styles from './ApplicationGrid.module.scss'
import { AppCard } from '../AppCard'

export function ApplicationGridDesktop({
  applications, noAppsMsg, errorContactMsg, onClick, buttonLinkText, favoriteLabelText
}) {
  const gridClass = styles['application-grid']
  const noAppsMessageClass = styles['no-applications-message']
  const errorContactMessageClass = styles['error-contact-message']

  return (
    <div className={gridClass}>
      {applications.length ? (
        applications.map((app) => (
          <AppCard
            key={app.appId}
            name={app.label}
            imageUrl={app.src}
            onOpenButtonClick={() => onClick(app.link)}
            buttonLinkText={buttonLinkText}
            favoriteLabelText={favoriteLabelText}
            onFavoriteClick={() => onClick()}
          />
        ))
      ) : (
        <div>
          <div className={noAppsMessageClass}> {noAppsMsg} </div>
          <div className={errorContactMessageClass}> {errorContactMsg} </div>
        </div>
      )}
    </div>
  )
}

ApplicationGridDesktop.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.shape({
    appId: PropTypes.string,
    src: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string.isRequired,
  })),
  noAppsMsg: PropTypes.string,
  errorContactMsg: PropTypes.string,
  onClick: PropTypes.func,
  buttonLinkText: PropTypes.string,
  favoriteLabelText: PropTypes.string
}

ApplicationGridDesktop.defaultProps = {
  applications: [],
  noAppsMsg: 'NoAppsMsg',
  errorContactMsg: 'ErrorContactMsg',
  onClick: () => {},
  buttonLinkText: '',
  favoriteLabelText: ''
}
