import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import styles from './Dashboard.module.scss'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import JNJFooter from '../../features/jnj-footer/JNJFooter'
import { ApplicationGrid } from '../../features/ApplicationGrid'
import { selectUserCallStatus } from '../../features/user/userSlice'
import SnackbarFeedbackOpp from '../../features/section-components/snackbarFeedbackOpp'
import { selectSurveyTemplateByTag } from '../Survey/surveyTemplateSlice'
import { SURVEY_TEMPLATE_TAG_HOME } from '../../util/config'

function DashboardMobile({
  t, isPageLoading, userObjData, userAppsData, appGridIsLoading, isSnackbarOpen,
  setSnackbarOpen, isModalOpen, setModalOpen
}) {
  const userCallStatus = useSelector(selectUserCallStatus)
  const surveySchema = useSelector(selectSurveyTemplateByTag)
  const buttonColors = useMemo(() => ['green', '#D7AD01', 'red'], [])

  return (
    <MarginWrapper>
      <div className={styles['dashboard-page']}>
        <LoadingIcon isLoading={isPageLoading}>
          <div className={styles['welcome-header-mobile']}>
            <Typography
              className={styles['welcome-header-mobile']}
              sx={{
                display: 'flex', fontFamily: "'JohnsonDisplay', Arial", fontWeight: 'bolder', color: 'black', lineHeight: '2', paddingInline: 1, paddingTop: 1, fontSize: 'calc(16px + 1vw)'
              }}
            >
              {`${t('WelcomeHeader')} ${userObjData.firstName || ''}! \n`}
            </Typography>
            <Typography
              className={styles['welcome-header']}
              sx={{
                display: 'flex', fontFamily: "'JohnsonDisplay', Arial", color: 'black', lineHeight: '0', paddingInline: 1, paddingBottom: 3.5, fontSize: 'calc(16px + 1vw)'
              }}
            >
              {`${t('ApplicationHeader')}`}
            </Typography>
          </div>
          {userAppsData.length ? (
            <div className={styles['middle-section']}>
              <LoadingIcon isLoading={appGridIsLoading || userCallStatus === 'failed'}>
                <div className={styles['app-grid']}>
                  <ApplicationGrid applications={userAppsData} noAppsMsg={t('NoAppsMsg')} errorContactMsg={t('ErrorContactMsg')} />
                </div>
              </LoadingIcon>
            </div>
          ) : (
            <div className={styles['middle-section-no-apps-mobile']}>
              <ApplicationGrid applications={userAppsData} noAppsMsg={t('NoAppsMsg')} errorContactMsg={t('ErrorContactMsg')} showLabels="true" />
            </div>
          )}
          <div>
            {surveySchema && (
              <SnackbarFeedbackOpp
                surveySchema={surveySchema}
                buttonColors={buttonColors}
                templateTag={SURVEY_TEMPLATE_TAG_HOME}
                isDelayed
                isSnackbarOpen={isSnackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                modalOpen={isModalOpen}
                setModalOpen={setModalOpen}
              />
            )}
          </div>
          <div className={styles['jnj-footer']}>
            <JNJFooter text={t('FooterText')} footerSize="medium" />
          </div>
        </LoadingIcon>
      </div>
    </MarginWrapper>
  )
}

DashboardMobile.propTypes = {
  t: PropTypes.func.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  userObjData: PropTypes.shape({
    login: PropTypes.string,
    status: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    preferredLanguage: PropTypes.string,
    secondEmail: PropTypes.string,
    displayName: PropTypes.string,
    created: PropTypes.string.isRequired,
    lastLogin: PropTypes.string.isRequired,
  }).isRequired,
  userAppsData: PropTypes.arrayOf(PropTypes.shape({
    // remember to define shape, placeholder
    appId: PropTypes.string.isRequired,
    appName: PropTypes.string.isRequired,
  })).isRequired,
  appGridIsLoading: PropTypes.bool.isRequired,
  isSnackbarOpen: PropTypes.bool.isRequired,
  setSnackbarOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired
}

export default DashboardMobile
