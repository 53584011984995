import React from 'react'
import PropTypes from 'prop-types'
import { SectionHeader } from '../../features/section-components/SectionHeader'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import styles from './Security.module.scss'

import JNJFooter from '../../features/jnj-footer/JNJFooter'

function SecurityDesktop({ t }) {
  const leftAlignStyle = {
    textAlign: 'left',
    fontFamily: "'JohnsonDisplay', Arial",
    marginLeft: '100px'
  }
  return (
    <MarginWrapper>
      <div style={leftAlignStyle}>
        <SectionHeader text={t('security-page-header')} size="large" />
        <h4>{t('security-page-subtitle')}</h4>
        <hr className={styles.topLine} />
        <s6>{t('security-page-message')}</s6>
      </div>
      <div className={styles['jnj-footer']}>
        <JNJFooter text={t('FooterText')} footerSize="medium" />
      </div>
    </MarginWrapper>
  )
}

SecurityDesktop.propTypes = {
  t: PropTypes.func.isRequired
}

export default SecurityDesktop
